import React from 'react';
import { graphql } from 'gatsby';
import { HTMLContent } from '../components/Content';

import Layout from '../components/Layout';

const FailedTestPageTemplate = ({ data }) => {
  const content = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <section className="main-section gray agreement-privacy">
        <div className="container">
          <HTMLContent className="agreement-container" content={content.text}></HTMLContent>
        </div>
      </section>
    </Layout>
  );
};

export default FailedTestPageTemplate;

export const FailedTestPageQuery = graphql`
  query FailedTestPage($id: String!) {
    markdownRemark(id: { eq: $id }) {     
      frontmatter {
          text           
      }       
    }
  }
`